import { useTranslation } from "react-i18next"
import { createFormFieldset, createEditableListFormField } from "../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import { DEFAULT_LIMIT } from "../../../lib/form-options-fetchers"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import ValueSelectorDialog from "../../../components/ValueSelectorDialog/ValueSelectorDialog"
import { getPickupPointsFilter } from "../../../lib/collection-filters"
import { useCache } from "../../../context/cache.context"


const LodgingPickupPointsForm = ({ selection, onAction }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const pickupPointsFilterField = getPickupPointsFilter(apiClient, t, true, cache)

    const fetcher = () =>
        apiClient.activityManager.lodging.getPickupPoints(selection.id, {
            limit: DEFAULT_LIMIT,
        })
            .then((response) => {
                selection.pickup_points = response
                return selection
            })

    const handleOnConfirm = (value) => {
        onAction({
            pickup_points: value.pickup_points.map((item) => { return { id: item.id } })
        })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "pickup_points",
                "",
                "point.name",
                t("lodgings.actions.set_pickup_points.actions.edit.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={pickupPointsFilterField.table}
                    selected={pickupPointsFilterField.selected}
                    valueFormatter={pickupPointsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true
            ),
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default LodgingPickupPointsForm